import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactTimeAgo from 'react-time-ago'

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const { register, handleSubmit, reset, formState } = useForm();

  const updatePosts = async (posts) => {
    const sortedEntries = posts.sort((function (a, b) {
                                return new Date(b.timestamp) - new Date(a.timestamp)
                              }))
    setPosts(sortedEntries);
  }

  const getPosts = async () => {
    const response = await fetch("https://post-space.nikolajjensen4913.workers.dev/posts", { mode: 'cors' });
    console.log(response)
    const jsonResponse = await response.json();
    updatePosts(jsonResponse);
  };

  const putPost = async (data) => {
    const response = await fetch("https://post-space.nikolajjensen4913.workers.dev/posts", { method: 'POST', mode: 'cors', body: JSON.stringify(data)});
    console.log(response)
  };

  useEffect(() => {
    getPosts();
  }, []);

  function onSubmit(data) {
    console.log(data)

    data.timestamp = new Date()
    data.username = "@" + data.username

    putPost(data);
    reset();
    let newPosts = [].concat(data, posts);
    updatePosts(newPosts);
  }

  return (
    <main role="main" className="container custom-container shadow-lg d-flex justify-content-center border border-dark extra-rounded">
      <div className="col">
        <div className="row d-flex justify-content-center">
          <div className="col-2 d-flex align-items-center">
            <i className="fas fa-sparkles fa-6x text-warning"></i>
          </div>
          <div className="col d-flex align-items-center">
            <div>
              <div>
               <h1 className="header-name"><strong>Post Space</strong></h1>
              </div>
              <div>
                <p className="header-subtitle">Feel free to post something!</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row top-buffer-lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label>Title</label>
              <input type="text" className="form-control" {...register('title')} placeholder="Title of your post..." required />
            </div>
            <div className="form-group mt-2">
              <label>Username</label>
              <div className="input-group">
                <span className="input-group-text bg-secondary text-white"><i className="fas fa-at fa-lg"></i></span>
                <input type="text" className="form-control" placeholder="Username" {...register('username')} aria-label="Username" required />
              </div>
            </div>
            <div className="form-group mt-2">
              <label>Content</label>
              <textarea className="form-control" {...register('content')} rows="3" required></textarea>
            </div>
            <div className="form-group mt-2">
              <div className="input-group">
                <span className="input-group-text bg-danger text-white"><i className="fas fa-link fa-lg"></i></span>
                <input type="text" className="form-control" name="link" placeholder="Paste a link" {...register('link', {
                  pattern: {
                    value: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                    message: 'Please a valid link.' }
                  })} />
              </div>

              {formState.errors.link?.message &&
                <div className="row text-danger fst-italic float-end">
                  <p><small>{formState.errors.link?.message}</small></p>
                </div>
              }
            </div>
            <div className="form-group mt-2">
              <div className="input-group">
                <span className="input-group-text bg-success text-white"><i className="fas fa-image fa-lg"></i></span>
                <input type="text" className="form-control" name="photo" placeholder="Paste a link to a photo" {...register('image', {
                  pattern: {
                    value: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)\.(?:jpg|gif|png)$/,
                    message: 'Please link only to a .jpg, .gif, or .png file.' }
                  })}/>
              </div>
              <div className="row">
              {formState.errors.image?.message &&
                <div>
                  <p className="text-danger fst-italic mb-0 float-end"><small>{formState.errors.image?.message}</small></p>
                </div>
              }
              </div>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary mt-2 mb-2 float-end">Post!</button>
            </div>
          </form>
        </div>

        <div className="row">
          <hr className="solid" />
        </div>

        {posts.map((post, i) => (
          <div className="alert alert-primary p-0" role="alert" key={i}>
            {post.image &&
              <img src={post.image} className="rounded" alt="From post" />
            }
            <div className="m-2">
              <div className="row">
                <h4 className="mb-0">{post.title}</h4>
              </div>
              <div className="row mt-0">
                <p className="fw-light fst-italic m-0"><small>{post.username} &bull; <ReactTimeAgo date={new Date(post.timestamp)} /></small></p>
              </div>
              <div className="row">
                <p className="text-justify">{post.content}</p>
              </div>
              {post.link &&
                <div className="row">
                  <div>
                    <a href={post.link} target="_blank" className="btn btn-sm btn-outline-danger btn-rounded float-end"><i className="fas fa-link fa-md me-2" />Open link</a>
                  </div>
                </div>
              }
            </div>
          </div>
        ))}

        <div className="row">
          <hr className="solid" />
        </div>

        <div className="row">
          <div className="d-flex justify-content-center">
            <p className="text-secondary"><small>&copy; Post Space 2021</small></p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Posts
